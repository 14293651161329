import React, { useContext } from 'react';
import Link from '../Link';
import PractitionerStatusMessage from '../PractitionerStatusMessage';
import { AuthContext } from '../../context/auth-context';
import { PRACTITIONER_STATUS } from '../../data/constants';

const ns = `dashboard`;

const Dashboard = ({ accountLinks = [] }) => {
	const { state: authState, actions: authActions } = useContext(AuthContext);
	const { auth, practitioner } = authState;
	const { profile = {} } = auth;
	const practitionerStatus = practitioner.status || '';
	const isIncomplete = practitionerStatus.toUpperCase() === PRACTITIONER_STATUS.INCOMPLETE;
	const greetingText = profile.firstName ? `Hi, ${profile.firstName}!` : 'Hi!';

	const taglineMarkup = (
		<div className={`${ ns }__text`}>
			<div className={`${ ns }__text-name`}>
				{greetingText}
			</div>
			<Link
				className={`${ ns }__text-link`}
				to={'/'}
				sameTab={'_blank'}
				onClick={authActions.signOut}
			>
				Sign Out
			</Link>
		</div>
	);

	const continueRegistrationMarkup = isIncomplete && (
		<div className={`${ ns }__items ${ ns }__items--table-like`}>
			<div className={`${ ns }__item ${ ns }__item--message`}>
				<PractitionerStatusMessage
					component={'p'}
					className={`${ ns }__item-text`}
					linkProps={{ variant: 'white' }}
				/>
			</div>
			<div className={`${ ns }__item`} style={{ textAlign: 'center' }}>
				<Link
					sameTab
					className={`${ ns }__item-link`}
					to={'/account/register'}
					variant={'white'}
					onClick={() => { console.log('clicked'); }}
				>
					Continue Application
				</Link>
			</div>
		</div>
	);

	const accountLinksMarkup = !isIncomplete && (
		<div className={`${ ns }__items`}>
			{accountLinks.map(item => {
				return (
					<div key={item.label} className={`${ ns }__item`}>
						<Link
							className={`${ ns }__item-link`}
							to={item.url}
							sameTab={item.target && item.target !== '_blank'}
							variant={'white'}
							onClick={() => { console.log('clicked'); }}
						>
							{item.label}
						</Link>
					</div>
				);
			})}
		</div>
	);

	return (
		<div className={`${ ns }__container`}>
			{taglineMarkup}
			{continueRegistrationMarkup}
			{accountLinksMarkup}
		</div>
	);
};

export default Dashboard;
