import React, {
	useState, useEffect, useCallback, useRef, useContext
} from 'react';
import classNames from 'classnames';

import { AuthContext } from '../../context/auth-context';
import { PRACTITIONER_STATUS } from '../../data/constants';

import Link from '../Link';
import NavMobileMenu from '../NavMobileMenu';
import renderIcon from '../../utils/render-icon';

import { getMessagingForProtectedContent } from '../../utils/messaging';

const ns = `navigation`;

const Navigation = ({pathname, hideDropdowns, menuItems = [], menuCard = {}}) => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const { state: authState, actions: authActions } = useContext(AuthContext);
	const { auth, practitioner } = authState;
	const practitionerStatus = practitioner.status || '';

	const isUserStatusActive = practitionerStatus.toUpperCase() === PRACTITIONER_STATUS.ACTIVE;

	const navItems = {
		shop: [],
		whyNordic: [],
		learn: [],
	};

	let menuIndex;

	menuIndex = 0;
	if (menuItems[menuIndex]) {
		navItems.shop = [{
			title: menuItems[menuIndex].label,
			highlight: menuItems[menuIndex].highlightFirstLink,
			links: menuItems[menuIndex].links
				.map(el => {
					return {
						label: el.link.title,
						url: el.link.url,
						target: el.link.target,
					};
				}),
		}];

		menuItems[menuIndex].megaMenu.forEach(el => {
			navItems.shop.push({
				title: el.label,
				links: el.links.map(el2 => {
					return {
						label: el2.link.title,
						url: el2.link.url,
						target: el2.link.target,
					};
				}),
			});
		});
	}

	menuIndex = 1;
	if (menuItems[menuIndex]) {
		navItems.learn = [{
			title: menuItems[menuIndex].label,
			links: menuItems[menuIndex].links.map(el => {
				return {
					label: el.link.title,
					url: el.link.url,
					target: el.link.target,
				};
			}),
		}];
	}

	menuIndex = 2;
	if (menuItems[menuIndex]) {
		navItems.whyNordic = [{
			title: menuItems[menuIndex].label,
			links: menuItems[menuIndex].links.map(el => {
				return {
					label: el.link.title,
					url: el.link.url,
					target: el.link.target,
				};
			}),
		}];
	}

	const [activeMenu, setActiveMenu] = useState('');
	const [dropdownHeight, setDropdownheight] = useState(0);
	const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
	const [hoverMod, setHoverMod] = useState(false);
	const [hoverNavigationEnabled, setHoverNavigationEnabled] = useState(true);
	const navRef = useRef();

	const handleMenu = (e, menu) => {
		const dropdown = e.currentTarget.nextSibling;
		const dropdownContainer = dropdown?.childNodes[0];

		if (menu === 'shop') {
			setHoverMod(true);
		}

		if (dropdownContainer) {
			setDropdownheight(dropdownContainer.offsetHeight);
		}

		if (menu === activeMenu) {
			setActiveMenu('');
		} else {
			setActiveMenu(menu);
		}
	};

	const handleNavClosure = useCallback(event => {
		if (!navRef.current.contains(event.target)) {
			setActiveMenu('');
			setDropdownheight(0);
		}
	}, []);

	useEffect(() => {
		const isTouchDevice = () => {
			return 'ontouchstart' in window || navigator.maxTouchPoints;
		};
		if (isTouchDevice()) {
			setHoverNavigationEnabled(false);
		} else {
			setHoverNavigationEnabled(true);
		}
	}, []);

	useEffect(() => {
		if (hideDropdowns) {
			setActiveMenu('');
			setDropdownheight(0);
		}
	}, [hideDropdowns]);

	useEffect(() => {
		setActiveMenu('');
		setDropdownheight(0);
	}, [pathname]);

	useEffect(() => {
		window.addEventListener('click', handleNavClosure);

		return () => {
			window.removeEventListener('click', handleNavClosure);
		};
	}, [handleNavClosure]);

	const openMenuHandler = () => {
		setMobileMenuOpen(true);
		document.body.classList.add('modal-open');
	};

	const closeMenuHandler = () => {
		setMobileMenuOpen(false);
		document.body.classList.remove('modal-open');
	};

	return (
		<nav
			className={`${ rootClassnames } navStyle`}
			ref={navRef}
			onMouseLeave={() => {
				setActiveMenu('');
			}}
		>
			<div className={`${ ns }__option`}>
				<button
					onClick={e => {
						handleMenu(e, 'shop');
					}}
					onMouseEnter={e => {
						hoverNavigationEnabled && handleMenu(e, 'shop');
					}}
				>
					Shop
					{renderIcon('carrot-down')}
				</button>
				<div
					className={`${ ns }__dropdown-container shop ${ activeMenu === 'shop' ? 'active' : '' }`}
					style={{
						height: activeMenu === 'shop' ? dropdownHeight : 0,
					}}
					onMouseEnter={() => {
						setHoverMod(false);
					}}
				>
					<div className={`${ ns }__dropdown`}>
						<div className={`${ ns }__dropdown-content`}>
							<div className={navItems.shop[0].highlight ? 'product-type highlight-first' : 'product-type'}>
								{navItems.shop[0] && navItems.shop[0].links.map(value => {
									return (
										<Link
											key={value.label}
											className={'product-type-nav-link'}
											to={value.url}
											sameTab={value.target && value.target !== '_blank'}
											variant={'navy'}
										>
											{value.label}
										</Link>
									);
								})}
							</div>
							<div className={`health-intrest`}>
								{navItems.shop.map((value, index) => {
									if (index > 0) {
										return (
											<div className={`health-intrest-column`} key={value.title}>
												<p className={`health-intrest-title`}>{value.title}</p>
												{value.links.map(link => {
													return (
														<Link
															className={'nav-link'}
															to={link.url}
															sameTab={value.target && value.target !== '_blank'}
															key={link.label}
															variant={'navy'}
														>
															{link.label}
														</Link>
													);
												})}
											</div>
										);
									}
									return true;
								})}
							</div>
							<div className={`card`}>
								<div className={`card-container`}>
									{menuCard.eyebrow && <p>{menuCard.eyebrow}</p>}
									{menuCard.headline && <h5>{menuCard.headline}</h5>}
									{menuCard.cta?.url && menuCard.cta?.title && (
										<Link to={`/products/nordic-naturals${menuCard.cta.url}`}>
											{menuCard.cta.title}
										</Link>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={`${ ns }__option`}>
				<button
					onClick={e => {
						handleMenu(e, 'learn');
					}}
					onMouseEnter={e => {
						hoverNavigationEnabled && handleMenu(e, 'learn');
					}}
				>
					Learn
					{renderIcon('carrot-down')}
				</button>
				<div
					className={`${ ns }__dropdown-container learn ${ activeMenu === 'learn' ? 'active' : '' }`}
					style={{
						height: activeMenu === 'learn' ? dropdownHeight : 0,
					}}
				>
					<div className={`${ ns }__dropdown`}>
						{navItems.learn[0] && navItems.learn[0].links.map(link => {
							let linkUrl = link.url;
							let clickHandler;

							//	--------------------------
							//	Protected Content Handling
							//	--------------------------
							const isProtectedContent = /gateway\.on24\.com/.test(linkUrl);

							if (isProtectedContent && !isUserStatusActive) {
								const message = getMessagingForProtectedContent(false, true);
								linkUrl = '#';
								clickHandler = () => {
									window.alert(message);
								};
							}

							return (
								<div className={`${ ns }__dropdown-link`} key={link.label}>
									<Link
										to={linkUrl}
										sameTab={link.target && link.target !== '_blank'}
										variant={'navy'}
										onClick={clickHandler}
									>
										{link.label}
									</Link>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div className={`${ ns }__option`}>
				<button
					className={hoverMod ? 'why-button-mod' : ''}
					onClick={e => {
						handleMenu(e, 'why');
					}}
					onMouseEnter={e => {
						hoverNavigationEnabled && handleMenu(e, 'why');
						setHoverMod(false);
					}}
				>
					Why Nordic?
					{renderIcon('carrot-down')}
				</button>
				<div
					className={`${ ns }__dropdown-container why-nordic ${ activeMenu === 'why' ? 'active' : '' }`}
					style={{
						height: activeMenu === 'why' ? dropdownHeight : 0,
					}}
				>
					<div className={`${ ns }__dropdown`}>
						{navItems.whyNordic[0] && navItems.whyNordic[0].links.map(link => {
							return (
								<div className={`${ ns }__dropdown-link`} key={link.label}>
									<Link
										to={link.url}
										sameTab={link.target && link.target !== '_blank'}
										variant={'navy'}
									>
										{link.label}
									</Link>
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div className={`${ ns }__option-mobile`}>
				<button
					aria-label={`Toggle mobile menu`}
					onClick={
						() => {
							if (mobileMenuOpen) {
								closeMenuHandler();
							} else {
								openMenuHandler();
							}
						}
					}
				>
					<div />
					<div />
					<div />
				</button>
			</div>
			<NavMobileMenu
				open={mobileMenuOpen}
				onCloseCallback={closeMenuHandler}
				menuItems={navItems}
			/>
		</nav>
	);
};

export default Navigation;
