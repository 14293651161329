/* eslint-disable no-param-reassign */
/* eslint-disable prefer-template */

import {
	stateOptions,
	emptyAddress,
	practiceTypeOptions,
	specialtyOptions
} from '../data';

function parseNull(s) {
	return s === 'null' ? null : s;
}

// https://gist.github.com/kevinweber/1249fde7b3d26fe73e1be0d52d3c023a
export const phone = string => {
  let newString = string.match(/[0-9]{0,14}/g);

  if (newString === null) {
    return '';
  }

  // Join parts returned from RegEx match
  newString = newString.join('');

	if (newString.length === 10) {
		newString = '1' + newString;
	}

	// Start number with "+"
  newString = '+' + newString;

  // Limit length to 15 characters
  newString = newString.substring(0, 15);

  return newString;
};

// Shopify Address -> Local
export const fromShopifyAddress = (address = {}, decorations = {}) => {
	address = address || {};

	const province = address.province || '';
	const provinceCode = address.provinceCode || '';
	const state =
		stateOptions.find(opt => {
			const { value, label } = opt;
			return (
				value.toUpperCase() === provinceCode.toUpperCase() ||
				label.toUpperCase() === province.toUpperCase()
			);
		}) || emptyAddress.state;

	const data = {
		state,
		shopifyId: address.id,
		address1: address.address1 || '',
		address2: address.address2 || '',
		company: address.company || '',
		city: address.city || '',
		firstName: address.firstName || '',
		lastName: address.lastName || '',
		phone: address.phone || '',
		zip: address.zip || '',
		...decorations,
	};

	return Object.entries(data).reduce((acc, cur) => {
		const [k, v] = cur;
		acc[k] = parseNull(v);
		return acc;
	}, {});
};

// Local Address -> Shopify
export const toShopifyAddress = (address = {}) => {
	address = address || {};

  const state = address.state || {};

	const data = {
		address1: address.address1,
		address2: address.address2,
		city: address.city,
		company: address.company,
		country: address.country || 'United States',
		firstName: address.firstName,
		lastName: address.lastName,
		phone: address.phone,
		province: state.value,
		zip: address.zip,
	};

	return Object.entries(data).reduce((acc, cur) => {
		const [k, v] = cur;
		acc[k] = parseNull(v);
		return acc;
	}, {});
};

// Salesforce -> Local Address
export const fromSalesforceAddress = (address = {}, decorations = {}) => {
	address = address || {};

	const province = address.province || '';
	const normalizedProvince = province.toUpperCase();
	const state =
		stateOptions.find(option => {
			const { label, value } = option;
			return (
				value.toUpperCase() === normalizedProvince ||
				label.toUpperCase() === normalizedProvince
			);
		}) || emptyAddress.state;

	return {
		state,
		firstName: address.firstName || '',
		lastName: address.lastName || '',
		company: address.name || '',
		address1: address.address1 || '',
		address2: address.address2 || '',
		city: address.city || '',
		zip: String(address.zip || ''),
		phone: String(address.phone || ''),
		secondaryPhone: String(address.phone_secondary || ''),
		website: address.website || '',
		...decorations,
	};
};

// Local Address -> Salesforce
const toSalesforceAddress = (address = {}) => {
	address = address || {};

	const { company, firstName, lastName } = address;
  const state = address.state || {};
	const name = company || `${firstName} ${lastName}`;

	return {
		name,
		address1: address.address1,
		address2: address.address2,
		city: address.city,
		province: state.value,
		zip: address.zip,
		country: address.country || 'United States',
		phone: address.phone,
		phone_secondary: address.secondaryPhone,
	};
};

// Auth0 User Info -> Local
export const fromAuth0Profile = (profile = {}, decorations = {}) => {
	profile = profile || {};

	return {
		email: profile.email || '',
		emailVerified: profile.email_verified || false,
		firstName: profile.given_name || '',
		fullName: profile.name || '',
		lastName: profile.family_name || '',
		metadata: profile.metadata || {},
		nickname: profile.nickname || '',
		sub: profile.sub || profile.user_id || '',
		shopify: {
			multipassToken: profile['shopify/multipassToken'] || '',
			customer: profile['shopify/customer'] || {},
		},
		userId: profile.sub || profile.user_id || '',
		...decorations,
	};
};

// Local User -> Auth0
export const toAuth0User = (user = {}, metadata = {}) => {
  const { firstName = '', lastName = '', email = '' } = user;
  const name = `${firstName} ${lastName}`.trim();

	const userMetadata = Object.entries(metadata).reduce((acc, cur) => {
		const [key, value] = cur;
		acc[key] = String(value);
		return acc;
	}, {});

  return {
    name,
    email: email.toLowerCase(),
    family_name: lastName,
    given_name: firstName,
    nickname: user.nickname || firstName,
    password: user.password,
    username: user.username,
		user_metadata: userMetadata,
  };
};

export const fromSalesforceUser = (practitioner = {}, business = {}, decorations = {}) => {
	const practiceType = business.practiceType || '';
	const specialty = business.specialty || '';
	const normalizedPracticeType = practiceType.toUpperCase();
	const normalizedSpecialty = specialty.toUpperCase();
	const localPracticeType =
		practiceTypeOptions.find(p => {
			const { value, label } = p;
			return (
				value.toUpperCase() === normalizedPracticeType ||
				label.toUpperCase() === normalizedPracticeType
			);
		}) || '';

	const localSpecialty =
		specialtyOptions.find(s => {
			const { value, label } = s;
			return (
				value.toUpperCase() === normalizedSpecialty ||
				label.toUpperCase() === normalizedSpecialty
			);
		}) || '';

	return {
		firstName: practitioner.firstName,
		lastName: practitioner.lastName,
		email: practitioner.email,
		practiceType: localPracticeType,
		specialty: localSpecialty,
		...decorations,
	};
};

const normalize = {
	phone,
  address: {
    fromShopify: fromShopifyAddress,
    toShopify: toShopifyAddress,
    fromSalesforce: fromSalesforceAddress,
    toSalesforce: toSalesforceAddress,
  },
  profile: {
    fromAuth0: fromAuth0Profile,
  },
  user: {
		fromSalesforce: fromSalesforceUser,
    toAuth0: toAuth0User,
  },
};

export default normalize;
