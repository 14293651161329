/* eslint-disable no-restricted-globals */
/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */

import React from 'react';
import fetch from 'isomorphic-fetch';
import isEqual from 'lodash.isequal';
import isEmpty from 'lodash.isempty';
import camelCase from 'lodash.camelcase';

import {
	STOREFRONT_API_URL,
	STOREFRONT_API_ACCESS_TOKEN
} from './config';

export { default as normalize } from './normalize';
/**
 * Fetch Shopify Storefront API data
 * @param {string} graphql query
 */
export const getShopifyData = query => {
	return fetch(
		STOREFRONT_API_URL,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				'X-Shopify-Storefront-Access-Token': STOREFRONT_API_ACCESS_TOKEN,
			},
			body: JSON.stringify({ query }),
		}
	)
		.then(response => {
			if (response.status !== 200) {
				throw new Error('cannot fetch data');
			}
			return response;
		})
		.then(response => {
			return response.json();
		})
		.then(json => {
			if (json.errors?.length) {
				// eslint-disable-next-line
				console.error(`Storefront API key may not be set in this Product's Sales Channel`, json.errors);
			}
			return json;
		})
		.catch(error => {
			// eslint-disable-next-line
			console.error(error);
			return null;
		});
};

/**
 * Get an array of products given an array of product handles
 * @param {string} product handle
 */
export const getProductsByHandle = async(handlesArr = []) => {
	const promiseArray = [];

	handlesArr.forEach(handle => {
		const productQuery = `
			{
				product: productByHandle(handle: "${ handle }") {
					id
					title
					handle
					tags
					priceRange {
						minVariantPrice {
							amount
							currencyCode
						}
					}
					images(first: 1) {
						edges {
							node {
								altText
								originalSrc
							}
						}
					}
				}
			}
		`;

		promiseArray.push(getShopifyData(productQuery));
	});

	const response = await Promise.all(promiseArray);

	return response
		.filter(({ data }) => {
			return data?.product;
		})
		.map(({ data }) => {
			return {
				product: data.product,
			};
		});
};

/**
 * Get a Collection by its handle
 * @param {string} collection handle
 * @param {number} retrive the first x products
 */
export const getCollectionByHandle = async(handle = '', first = 3) => {
	const collectionQuery = `
		{
			collection: collectionByHandle(handle: "${ handle }") {
				title
				products(first: ${ first }) {
					edges {
						product: node {
							id
							title
							handle
							priceRange {
								minVariantPrice {
									amount
									currencyCode
								}
							}
							images(first: 1) {
								edges {
									node {
										altText
										originalSrc
									}
								}
							}
						}
					}
				}
			}
		}
	`;

	const response = await getShopifyData(collectionQuery);
	return response;
};

/**
 * Determine if url is an external url
 * @param {string} url.
 */
export const isExternalUrl = url => {
	let isExternal = false;

	if (url && (url.includes('//') || url.match(/((^(mailto|tel|sms|mms):)|www.)/) || url.includes('#'))) {
		isExternal = true;
	}

	return isExternal;
};

/**
 * Used to set meta image in SEO component (from seo object or image object)
 * @param {object} image
 * @param {object} seo
 */
export const setMetaImage = (image, seo) => {
	let metaImage = null;
	if (seo?.metaImage) {
		metaImage = seo.metaImage.sourceUrl;
	} else if (image) {
		metaImage = image.sourceUrl;
	}

	return metaImage;
};

/**
 * Return embed url and type given a YT or Vimeo url
 * @param {string} url.
 */
export const getVideoEmbedData = url => {
	if (!url) return false;

	const pattern = /(\/\/.+\/)(.+v=)?([a-zA-Z0-9-]+)($|\?.+)/;
	const matches = url.match(pattern);

	if (!matches) return false;

	const videoId = matches[3]; // Video ID is 3rd capturing group.
	let src;
	let type;

	if (url.indexOf('youtu') !== -1) {
		type = `youtube`;
		src = `https://www.youtube.com/embed/${ videoId }?autoplay=1`;
	} else if (url.indexOf('vimeo') !== -1) {
		type = `vimeo`;
		src = `https://player.vimeo.com/video/${ videoId }`;
	} else {
		return false;
	}

	return {
		type,
		src,
	};
};

/**
 * Return parsed json or false
 * @param {string} JSON.
 */

export const parseJson = json => {
	let output = false;

	if (json) {
		try {
			output = JSON.parse(json);
			return output;
		} catch {
			// eslint-disable-next-line
			console.error('error parsing related products');
		}
	}

	return output;
};

/**
 * Return Shopify metafield given a key
 * @param {array} metafields
 * @param {string} key
 */

export const getMetafield = (metafields = [], key = '') => {
	const output = metafields.find(field => {
		return field.key === key;
	});

	return output;
};

export const getDefaultSchema = () => {
	//	schema
	const structuredData = {
		'@context': 'https://schema.org/',
		'@type': 'Organization',
		name: 'Nordic Naturals',
		url: 'https://www.nordic.com/',
		address: '111 Jennings Drive, Watsonville, California 95076',
		location: '111 Jennings Drive, Watsonville, California 95076',
	};
	return structuredData;
};

/**
 * Formats a currency according to the user's locale
 * @param {string} currency The ISO currency code
 * @param {number} value The amount to format
 * @returns
 */

export const formatPrice = (currency, value) => {
	return Intl.NumberFormat('en-US', {
		currency,
		minimumFractionDigits: 2,
		style: 'currency',
	}).format(value);
};

export const stripURL = str => {
	if (!str) return undefined;
	return str.endsWith('/') ? str.slice(0, -1) : str;
};

export const validateEmail = email => {
	// eslint-disable-next-line
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	// eslint-disable-next-line
	return regexp.test(email);
};

/**
 * Breaks up or 'chunks' array to smaller parts
 * @param {array} array of items
 * @param {number} size the number of itmes per chunk
 * @returns
 */

export const chunkArray = (array, size) => {
	return array.reduce((chunks, item, i) => {
		if (i % size === 0) {
			chunks.push([item]);
		} else {
			chunks[chunks.length - 1].push(item);
		}
		return chunks;
	}, []);
};

/**
 * Renders ifram markup for Youtube or Vimeo given url
 * @param {string} url YT or Vimeo url
 * @returns
 */

export const getVideoIframe = url => {
	const pattern = /(\/\/.+\/)(.+v=)?([a-zA-Z0-9-]+)($|\?.+)/;
	const matches = url.match(pattern);

	if (!matches) return false;

	const videoId = matches[3]; // Video ID is 3rd capturing group.
	let iframeSrc;
	let type;

	if (url.indexOf('youtu') !== -1) {
		type = `youtube`;
		iframeSrc = `https://www.youtube.com/embed/${ videoId }`;
	} else if (url.indexOf('vimeo') !== -1) {
		type = `vimeo`;
		iframeSrc = `https://player.vimeo.com/video/${ videoId }`;
	} else {
		return false;
	}

	return (
		<div className={`iframe-container`}>
			<iframe
				src={iframeSrc}
				title={`${ type }-video`}
				width={640}
				height={360}
				frameBorder={`0`}
				allowFullScreen
			/>
		</div>
	);
};

export const tryParse = s => {
	try {
		return isNaN(s) ? JSON.parse(s) : s;
	} catch {
		return s;
	}
};

export const tryParseDeep = u => {
	try {
		const o = tryParse(u);
		const ret = Object.entries(o).reduce((acc, cur) => {
			const [k, v] = cur;
			const parsed = typeof v === 'string' ? tryParse(v) : tryParseDeep(v);
			acc[k] = parsed;
			return acc;
		}, o);

		return ret;
	} catch {
		return u;
	}
};

export const isObject = u => {
	return !!u && typeof u === 'object';
};

export const isPlainObject = u => {
	return isObject(u) && !Array.isArray(u);
};

export const objectDifference = (base = {}) => (derived = {}) => {
	const derivedKeys = Object.keys(derived);
	const difference = derivedKeys.reduce((acc, cur) => {
		const baseValue = base[cur];
		const derivedValue = derived[cur];
		const isSame = isEqual(baseValue, derivedValue);

		if (!isSame) {
			acc[cur] = isPlainObject(derivedValue) && isPlainObject(baseValue)
				? objectDifference(baseValue)(derivedValue)
				: derivedValue;
		}

		return acc;
	}, {});

	return difference;
};

export const isObjectDifferent = (initial = {}, updated = {}) => {
	const difference = objectDifference(initial)(updated);
	return !isEmpty(difference);
};

export const camelCaseObjectKeys = o => {
	if (!isObject(o)) {
		return o;
	}

	const model = Array.isArray(o) ? [] : {};
	const ret = Object.entries(o).reduce((acc, cur) => {
		const [key, value] = cur;
		const cKey = camelCase(key);
		const cValue = isObject(value) ? camelCaseObjectKeys(value) : value;
		acc[cKey] = cValue;
		return acc;
	}, model);

	return ret;
};

export const noop = () => () => {
	console.warn('Not implemented');
};

export const get = (input, path, defaultValue) => {
	try {
		let value = input;
		const fields = Array.isArray(path) ? path : path.split('.');

		for (let i = 0; i < fields.length; i++) {
			const field = fields[i];
			value = value[field];
		}

		return value ?? defaultValue;
	} catch {
		return defaultValue;
	}
};

// Only accepts specifiers 's' and 'd'
export const sprintf = (s, ...args) => {
	let pos = 0;
	const l = Array.isArray(args[0]) ? args[0] : args;
	const re = /%s|%d/g;
	const ret = s.replace(re, () => {
		const v = l?.[pos];
		pos += 1;
		return String(v);
	});

	return ret;
};

/**
 * Retrieves the value of the specified metafield key from the provided array.
 * @param {Array<Object>} metafields - An array of metafields objects.
 * @param {string} keyName - The key name to search for.
 * @returns {string} - The value of the specified metafield key, or undefined if not found.
 */
export const getMetafieldValue = (metafields, keyName) => {
  return metafields.find(metafield => metafield.key === keyName)?.value;
};

/**
 * Get an array of products given an array of product ids
 * @param {string} product id
 */
export const getProductsById = async(idsArr = []) => {
	const promiseArray = [];

	idsArr.forEach(id => {
		const productQuery = `
			{
				product(id: "${id}") {
					id
					title
					handle
					tags
					priceRange {
						minVariantPrice {
							amount
							currencyCode
						}
					}
					images(first: 1) {
						edges {
							node {
								altText
								originalSrc
							}
						}
					}
				}
			}
		`;

		promiseArray.push(getShopifyData(productQuery));
	});

	const response = await Promise.all(promiseArray);

	return response
		.filter(({ data }) => {
			return data?.product;
		})
		.map(({ data }) => {
			return {
				product: data.product,
			};
		});
};

// Validates US zipcodes
export const isValidUSZip = s => {
	const regex = /^\d{5}(-\d{4})?$/;
	const isValid = regex.test(s);
	return isValid;
};
