/* eslint-disable import/no-mutable-exports */

/**
 * Shopify
 */
export const SHOPIFY_DOMAIN = process.env.GATSBY_SHOPIFY_PRIMARY_DOMAIN;

/**
 * Shopify Storefront API url
 */
export const STOREFRONT_API_URL = `https://${ process.env.GATSBY_SHOPIFY_STORE_URL }/api/${ process.env.GATSBY_SHOPIFY_STOREFRONT_API_VERSION }/graphql.json`;

/**
 * Shopify Storefront API access token
 */
export const STOREFRONT_API_ACCESS_TOKEN = process.env.GATSBY_STOREFRONT_ACCESS_TOKEN;

/**
 * Auth configurations
 */
export const AUTH_STORAGE_KEY = 'nordic_auth';
export const AUTH0_DATABASE = process.env.GATSBY_AUTH0_DATABASE;
export const AUTH0_DOMAIN = process.env.GATSBY_AUTH0_DOMAIN;
export const AUTH0_CLIENT_ID = process.env.GATSBY_AUTH0_CLIENT_ID;
export const AUTH0_SCOPE = 'openid email offline_access profile phone';
export const AUTH0_RESPONSE_TYPE = 'token id_token';
export const AUTH0_MANAGEMENT_IDENTIFIER = process.env.GATSBY_AUTH0_MANAGEMENT_IDENTIFIER;
export const AUTH0_MANAGEMENT_SCOPE = 'read:current_user create:current_user_metadata update:current_user_metadata';

export let AUTH0_REDIRECT_URI = process.env.GATSBY_AUTH0_REDIRECT_URI;
if (typeof window !== 'undefined' && !AUTH0_REDIRECT_URI) {
  AUTH0_REDIRECT_URI = window.location.origin;
}

/**
 * Nordic Salesforce API
 */
export const SALESFORCE_API_URL = `https://${process.env.GATSBY_SALESFORCE_API_URL}`;

/**
 * Test Data
 */
export const USE_TEST_DATA = Boolean(process.env.GATSBY_USE_TEST_DATA === 'true');
export const MY_FIRST_NAME = process.env.GATSBY_MY_FIRST_NAME || 'TEST';
export const MY_LAST_NAME = process.env.GATSBY_MY_LAST_NAME || 'USER';
export const MY_EMAIL = process.env.GATSBY_MY_EMAIL;
export const MY_PASSWORD = process.env.GATSBY_MY_PASSWORD;
