import React, {useContext} from 'react';
import classNames from 'classnames';
import uniqueId from 'uniqid';
import { FiChevronDown } from 'react-icons/fi';
import { useStaticQuery, graphql, navigate } from 'gatsby';

import { AuthContext } from '../../context/auth-context';
import Newsletter from '../../components/Newsletter';

import {
	Accordion,
	AccordionItem,
	AccordionControl,
	AccordionPanel
} from '../../components/Accordion';
import Link from '../../components/Link';
import Button from '../../components/Button';
import RenderStaticHTML from '../../components/RenderStaticHTML';

import renderIcon from '../../utils/render-icon';
import { chunkArray } from '../../utils';
import footerItems from './footerItems.json';

const ns = `site-footer`;
const Footer = ({ pathname }) => {
	const { state: authState } = useContext(AuthContext);
	const { auth = {} } = authState;

	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const {
		wp: {
			acfOptionsFooter: {
				footerSettings: {
					footerCtas,
					contactInformation,
				},
			},
			globalSettings: {
				globalSettings: {
					footerNavigation,
					footerLegalTextArea,
					footerBottomRowLinks,
					footerCopyright,
					footerCorporateSitesDropdown,
				},
			},
		},
	} = useStaticQuery(
		graphql`
			query {
				wp {
					acfOptionsFooter {
						footerSettings {
							contactInformation {
								address
								email
								phoneNumber
							}
							footerCtas {
								leftSide {
									eyebrow
									title
									description
									link {
										label
										url
									}
								}
								rightSide {
									eyebrow
									title
									description
									link {
										label
										url
									}
								}
							}
						}
					}
					globalSettings {
						globalSettings {
							footerLegalTextArea {
								text
							}
							footerCorporateSitesDropdown {
								link {
									target
									title
									url
								}
							}
							footerCopyright
							footerNavigation {
								fieldGroupName
								label
								links {
									link {
										target
										title
										url
									}
								}
							}
							footerBottomRowLinks {
								link {
									title
									target
									url
								}
							}
						}
					}
				}
			}
		`
	);

	// navigate on select change -__-
	const onSelectChange = e => {
		const {
			value,
		} = e.target;

		navigate(value);
	};

	const isAccountPage = pathname.startsWith('/account');

	return (
		<footer className={rootClassnames}>
			{!auth.isAuthenticated && !isAccountPage && (
				<div className={`${ ns }__ctas-container `}>
					{footerCtas.leftSide && (
						<div className={`${ ns }__left-cta-container`}>
							<div className={`${ ns }__left-cta`}>
								{footerCtas.leftSide.eyebrow && (
									<p className={`${ ns }__cta-eyebrow`}>{footerCtas.leftSide.eyebrow}</p>
								)}
								{footerCtas.leftSide.title && (
									<p className={`${ ns }__cta-title`}>{footerCtas.leftSide.title}</p>
								)}
								{footerCtas.leftSide.description && (
									<p className={`${ ns }__cta-description`}>{footerCtas.leftSide.description}</p>
								)}
								{footerCtas.leftSide.link.label && footerCtas.leftSide.link.url && (
									<Button
										className={`${ ns }__cta-button`}
										to={footerCtas.leftSide.link.url}
										variant={'white'}
									>
										{footerCtas.leftSide.link.label}

									</Button>
								)}
							</div>
						</div>
					)}
					{footerCtas.rightSide && (
						<div className={`${ ns }__right-cta-container`}>
							<div className={`${ ns }__right-cta`}>
								{footerCtas.rightSide.eyebrow && (
									<p className={`${ ns }__cta-eyebrow`}>{footerCtas.rightSide.eyebrow}</p>
								)}
								{footerCtas.rightSide.title && (
									<p className={`${ ns }__cta-title`}>{footerCtas.rightSide.title}</p>
								)}
								{footerCtas.rightSide.description && (
									<p className={`${ ns }__cta-description`}>{footerCtas.rightSide.description}</p>
								)}
								{footerCtas.rightSide.link.label
								&& footerCtas.rightSide.link.url && (
									<Button
										className={`${ ns }__cta-button`}
										to={footerCtas.rightSide.link.url}
										variant={'white'}
									>
										{footerCtas.rightSide.link.label}

									</Button>
								)}
							</div>
						</div>
					)}
				</div>
			)}
			<div className={`${ ns }__contianer`}>
				<div className={'container'}>
					<div className={`${ ns }__top`}>
						<div className={`${ ns }__newsletter`}>
							<div className={`${ ns }__newsletter-text h3`}>
								Sign up for news events, and more.
							</div>
							<div style={{width: '100%'}}>
								{process.env.GATSBY_KLAVIYO_FORM_ID_FOOTER && (
									<Newsletter
										newsletterListId={process.env.GATSBY_KLAVIYO_FORM_ID_FOOTER}
									/>
								)}
								<div className={`${ ns }__social`}>
									{footerItems.social.map(socialItem => {
										return (
											<Link variant={'white'} to={socialItem.url} key={uniqueId('nn')}>
												{socialItem.icon !== null && (
													<span className={`visually-hidden`}>{ socialItem.icon }</span>
												)}
												{renderIcon(socialItem.icon)}
											</Link>
										);
									})}
								</div>
							</div>
						</div>
					</div>
					<div className={`${ ns }__center`}>
						<PrimaryNavAccordion
							items={footerNavigation}
							contactInformation={contactInformation}
						/>
						<PrimaryNav
							items={footerNavigation}
							contactInformation={contactInformation}
						/>
					</div>
					<button type={'button'} className={`${ ns }__acsb-trigger`} data-acsb-custom-trigger={'true'}>
						{renderIcon('accessibility')}
					</button>
					<div className={`${ ns }__bottom`}>
						{contactInformation && (
							<div className={`${ ns }__contact-info--mobile`}>
								{contactInformation.address && (
									<RenderStaticHTML
										className={`${ ns }__contact-address`}
										html={contactInformation.address}
									/>
								)}
								{contactInformation.phoneNumber && (
									<p className={`${ ns }__contact-phone`}>{contactInformation.phoneNumber}</p>
								)}
								{contactInformation.email && (
									<p className={`${ ns }__contact-email`}>{contactInformation.email}</p>
								)}
							</div>
						)}
						<div className={`${ ns }__bottom-links`}>
							<div className={`${ ns }__select ${ ns }__select--corporate`}>
								<span aria-hidden>
									<FiChevronDown size={20} />
								</span>
								<select onChange={onSelectChange}>
									{/* eslint-disable-next-line max-len */}
									{footerCorporateSitesDropdown && footerCorporateSitesDropdown.map(l => {
										const { link } = l;
										return (
											<option key={uniqueId('nn')} value={link.url}>{link.title}</option>
										);
									})}
								</select>
							</div>
							<div className={`${ ns }__legal`}>
								{footerBottomRowLinks && footerBottomRowLinks.map((l => {
									const { link } = l;
									return (
										<Link to={link.url} key={uniqueId('nn')} variant={'white'}>
											{link.title}
										</Link>
									);
								}))}
							</div>
						</div>
						<div className={`${ ns }__copyright-disclosures`}>
							<div className={`${ ns }__copyright`}>
								<RenderStaticHTML html={footerCopyright} />
							</div>
							<div className={`${ ns }__disclosures`}>
								{footerLegalTextArea && footerLegalTextArea.map(disclosure => {
									return (
										<div className={`${ ns }__disclosure`} key={uniqueId('nn')}>
											{disclosure.text}
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

// Navigation functions

function PrimaryNav({ items, contactInformation }) {
	if (!items) return null;

	return (
		<nav>
			<ul>
				{items.map(({ label, links }, index) => {
					const chunkedLinks = chunkArray(links, 4);
					return (
						<li key={uniqueId('nn')}>
							{label && (
								<div className={`h4`}>
									{label}
								</div>
							)}
							{(links?.length > 0) && (index === 0) && (
								<div className={`${ ns }__primary-links extended`}>
									{chunkedLinks.map(arr => {
										return (
											<ul key={uniqueId('nn')}>
												{arr.map(({link: { title, url }}) => {
													return (
														<li key={uniqueId('nn')}>
															<Link to={url} variant={'white'}>{title}</Link>
														</li>
													);
												})}
											</ul>
										);
									})}
								</div>
							)}
							{(links?.length > 0) && (index > 0) && (
								<div className={`${ ns }__primary-links`}>
									<ul>
										{links.map(({link: { title, url }}) => {
											if (!label || !url) return null;

											return (
												<li key={uniqueId('nn')}>
													<Link to={url} variant={'white'}>{title}</Link>
												</li>
											);
										})}
									</ul>
								</div>
							)}
						</li>
					);
				})}
			</ul>
			{contactInformation && (
				<div className={`${ ns }__contact-info`}>
					{contactInformation.address && (
						<RenderStaticHTML
							className={`${ ns }__contact-address`}
							html={contactInformation.address}
						/>
					)}
					{contactInformation.phoneNumber && (
						<p className={`${ ns }__contact-phone`}>{contactInformation.phoneNumber}</p>
					)}
					{contactInformation.email && (
						<p className={`${ ns }__contact-email`}>{contactInformation.email}</p>
					)}
				</div>
			)}
		</nav>
	);
}

function PrimaryNavAccordion({ items }) {
	if (!items) return null;

	return (
		<Accordion>
			{items.map(({ label, links }) => {
				if (!label || !items.length) return null;

				return (
					<AccordionItem key={uniqueId('nn')}>
						<AccordionControl>
							<span className={`h4`}>
								{label}
							</span>
							<span>
								<FiChevronDown size={20} />
							</span>
						</AccordionControl>
						<AccordionPanel>
							<ul>
								{links.map(({link: { title, url }}) => {
									if (!title || !url) return null;

									return (
										<li key={uniqueId('nn')}>
											<Link to={url} variant={'white'}>{title}</Link>
										</li>
									);
								})}
							</ul>
						</AccordionPanel>
					</AccordionItem>
				);
			})}
		</Accordion>
	);
}
