import React from 'react';

import Account from '../icons/Account';
import Cart from '../icons/Cart';
import CarrotDown from '../icons/CarrotDown';
import CarrotLeft from '../icons/CarrotLeft';
import CarrotRight from '../icons/CarrotRight';
import Checkbox from '../icons/Checkbox';
import Facebook from '../icons/Facebook';
import Instagram from '../icons/Instagram';
import Location from '../icons/Location';
import Logo from '../icons/Logo';
import Menu from '../icons/Menu';
import PDF from '../icons/PDF';
import Pinterest from '../icons/Pinterest';
import Search from '../icons/Search';
import Star from '../icons/Star';
import Twitter from '../icons/Twitter';
import X from '../icons/X';
import XL from '../icons/XL';
import Accessibility from '../icons/Accessibility';
import Sunwave from '../icons/Sunwave';
import Upload from '../icons/Upload';
import ThumbsDown from '../icons/ThumbsDown';
import ThumbsUp from '../icons/ThumbsUp';
import Cross from '../icons/Cross';

const renderIcon = icon => {
	if (!icon) return null;

	switch (icon) {
	case 'account':
		return <Account />;
	case 'cart':
		return <Cart />;
	case 'carrot-down':
		return <CarrotDown />;
	case 'carrot-left':
		return <CarrotLeft />;
	case 'carrot-right':
		return <CarrotRight />;
	case 'checkbox':
		return <Checkbox />;
	case 'facebook':
		return <Facebook />;
	case 'instagram':
		return <Instagram />;
	case 'location':
		return <Location />;
	case 'logo':
		return <Logo />;
	case 'menu':
		return <Menu />;
	case 'pdf':
		return <PDF />;
	case 'pinterest':
		return <Pinterest />;
	case 'search':
		return <Search />;
	case 'star':
		return <Star />;
	case 'twitter':
		return <Twitter />;
	case 'x':
		return <X />;
	case 'xl':
		return <XL />;
	case 'accessibility':
		return <Accessibility />;
	case 'sunwave':
		return <Sunwave />;
	case 'upload':
		return <Upload />;
	case 'thumbsUp':
		return <ThumbsUp />;
	case 'thumbsDown':
		return <ThumbsDown />;
	case 'cross':
		return <Cross />;
	default:
		return null;
	}
};

export default renderIcon;
