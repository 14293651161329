import React, {
	createContext,
	useState,
	useEffect,
	useContext,
} from 'react';
import fetch from 'isomorphic-fetch';
import Client from 'shopify-buy';
import isEmpty from 'lodash.isempty';
import { TrackingContext } from './tracking-context';
import { AuthContext } from './auth-context';
import { shopify } from '../utils/clients';
import { tryParse, isPlainObject } from '../utils';

const client = Client.buildClient(
	{
		domain: process.env.GATSBY_SHOPIFY_STORE_URL,
		storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN,
		apiVersion: process.env.GATSBY_SHOPIFY_STOREFRONT_API_VERSION,
	},
	fetch
);

const defaultValues = {
	isOpen: false,
	loading: false,
	onOpen: () => {},
	onClose: () => {},
	onFlyoutCartOpen: () => {},
	onFlyoutCartClose: () => {},
	addVariantToCart: () => {},
	removeLineItem: () => {},
	updateLineItem: () => {},
	client,
	checkout: {
		lineItems: [],
	},
	cart: {
		lineItems: [],
	},
};

export const StoreContext = createContext(defaultValues);

const isBrowser = typeof window !== `undefined`;
// const localStorageKey = `shopify_checkout_id`;
const localStorageCartKey = 'shopify_cart_id';

export const StoreProvider = ({ children }) => {
	const { actions: trackingActions } = useContext(TrackingContext);
	const { state: authState } = useContext(AuthContext);
	// const [checkout, setCheckout] = useState(defaultValues.checkout);
	const [cart, setCart] = useState(defaultValues.cart);
	const [loading, setLoading] = useState(false);
	const [didJustAddToCart, setDidJustAddToCart] = useState(false);
	const [isFlyoutCartOpen, setIsFlyoutCartOpen] = React.useState(() => {
		// If cart-open=true, open cart flyout on page load
		const params = new URLSearchParams(window.location.search);
		return params.get('cart-open') === 'true';
	});

	const setCartItem = (shopifyCart, dictionary) => {
		if (isBrowser && dictionary) {
			localStorage.setItem(localStorageCartKey, JSON.stringify(dictionary));
		}

		setCart(shopifyCart);
	};

	useEffect(() => {
		(async () => {
			try {
				const email = authState?.auth?.profile?.email || null;
				const identifier = String(email);
				const trackingParams = trackingActions.getTrackingObj();
				const fetchOptions = { params: trackingParams };

				const storageValue = isBrowser
					? localStorage.getItem(localStorageCartKey)
					: null;

				let dictionary = tryParse(storageValue) || {};

				if (!isPlainObject(dictionary)) {
					dictionary = {};
				}

				let cartId = dictionary[identifier];

				if (email && !cartId) {
					cartId = dictionary.null;
					dictionary[identifier] = cartId;
					dictionary.null = undefined;
				}

				const getOrCreateCart = cartId
					? shopify.getCart.bind(null, cartId)
					: shopify.createCart.bind(null, undefined);
				let shopifyCart = await getOrCreateCart(fetchOptions);

				if (isEmpty(shopifyCart)) {
					shopifyCart = await shopify.createCart(undefined, fetchOptions);
				}

				dictionary[identifier] = shopifyCart.id;
				setCartItem(shopifyCart, dictionary);
			} catch (error) {
				console.error(error.json || error);
				setCartItem(defaultValues.cart, {});
			}
		})();
	}, [authState?.auth?.profile?.email, trackingActions.getTrackingObj]);

	const addVariantToCart = (
		variantId,
		quantity,
		sellingPlanId,
		sellingPlanGroup
	) => {
		setLoading(true);

		const cartID = cart.id;
    const lineItemsToUpdate = [
      {
        merchandiseId: variantId,
        quantity: parseInt(quantity, 10),
		sellingPlanId,
        // Adds customAttributes used for Recharge subcription cart rendering
        ...(sellingPlanId &&
          sellingPlanGroup && {
            attributes: [
              {
                key: '_sellingPlanId',
                value: `${sellingPlanId}`,
              },
              {
                // adding this for easy access across the application
                key: '_sellingPlanGroup',
                value: JSON.stringify(sellingPlanGroup),
              },
            ],
          }),
      },
    ];

    return shopify
      .addCartLines(cartID, lineItemsToUpdate)
      .then(res => {
        setCart(res);
        setLoading(false);
        setDidJustAddToCart(true);
        setTimeout(() => {
          return setDidJustAddToCart(false);
        }, 250);
      })
      .catch(error => {
        console.error(error.json || error.message);
      });
	};

	const removeLineItem = (cartID, lineItemID) => {
		setLoading(true);

    return shopify
      .removeCartLines(cartID, [lineItemID])
      .then(res => {
        setCart(res);
        setLoading(false);
      })
      .catch(error => {
        console.error(error.json || error.message);
      });
	};

	const updateLineItem = (cartID, lineItemID, quantity) => {
		setLoading(true);

		const lineItemsToUpdate = [
			{ id: lineItemID, quantity: parseInt(quantity, 10) },
		];

    return shopify
      .updateCartLines(cartID, lineItemsToUpdate)
      .then(res => {
        setCart(res);
        setLoading(false);
      })
      .catch(error => {
        console.error(error.json || error.message);
      });
	};

	const onFlyoutCartOpen = () => {
		if (!isFlyoutCartOpen) setIsFlyoutCartOpen(true);
	};

	const onFlyoutCartClose = () => {
		setIsFlyoutCartOpen(false);
	};

	return (
		<StoreContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				...defaultValues,
				addVariantToCart,
				didJustAddToCart,
				removeLineItem,
				updateLineItem,
				isFlyoutCartOpen,
				onFlyoutCartOpen,
				onFlyoutCartClose,
				cart,
				loading,
				checkout: cart,
			}}
		>
			{children}
		</StoreContext.Provider>
	);
};
