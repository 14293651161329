import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';
import uniqueId from 'uniqid';

import Image from '../Image';
import { getProductsByHandle, formatPrice } from '../../utils';

const ns = `cart-product-slider`;

const Arrow = ({ className, onClick }) => {
	return (
		<button className={className} onClick={onClick}>
			<svg
				width={'100%'}
				height={'100%'}
				viewBox={'0 0 19 32'}
				fill={'none'}
				xmlns={'http://www.w3.org/2000/svg'}
			>
				<path
					fillRule={'evenodd'}
					clipRule={'evenodd'}
					d={'M4.09047 1.20515C3.36002 0.525875 2.22903 0.525876 1.49858 1.20515C0.689124 1.95789 0.689126 3.23957 1.49858 3.99231L11.2611 13.0708C12.9625 14.653 12.9625 17.347 11.2611 18.9292L1.49858 28.0077C0.689124 28.7604 0.689123 30.0421 1.49858 30.7949C2.22903 31.4741 3.36002 31.4741 4.09047 30.7949L16.8501 18.9292C18.5515 17.347 18.5515 14.653 16.8501 13.0708L4.09047 1.20515Z'}
					fill={'currentColor'}
				/>
			</svg>
		</button>
	);
};

const CartProductSlider = () => {
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const [products, setProducts] = useState([]);
	const [nav1, setNav1] = useState(null);
	const [nav2, setNav2] = useState(null);

	useEffect(() => {
		// Todo: Add logic to fetch products based on practitioner account type
		const handles = [
			'ultimate-omega-2x-sport',
			'algae-omega',
			'multi-minerals-without-iron-copper',
			'arctic-cod-liver-oil-pro',
		];
		getProductsByHandle(handles)
			.then(allProducts => {
				setProducts(allProducts);
			});
	}, []);

	if (!products.length) {
		return null;
	}

	return (
		<div className={rootClassnames}>
			<div className={`${ ns }__nav`}>
				<div className={`${ ns }__nav-title`}>
					<p>Customers also bought:</p>
				</div>
				<Slider
					ref={setNav1}
					asNavFor={nav2}
					nextArrow={<Arrow />}
					prevArrow={<Arrow />}
				>
					{products.map(() => {
						return (
							<span key={uniqueId()} />
						);
					})}
				</Slider>
			</div>
			<div className={`${ ns }__slider`}>
				<Slider
					ref={setNav2}
					asNavFor={nav1}
					arrows={false}
					slidesToShow={2}
					slidesToScroll={2}
					infinite
				>
					{products.map(({ product }, index) => {
						const flatProduct = {
							...product,
							...product.images?.edges?.[0].node,
							...product.priceRange?.minVariantPrice,
						};

						return (
							<div className={`${ ns }__slide`} key={uniqueId('nn')}>
								<div className={`${ ns }__slide-image`}>
									<Image
										sourceUrl={flatProduct.src || flatProduct.originalSrc}
										altText={flatProduct.altText || `Product Image ${ index }`}
									/>
								</div>
								<div className={`${ ns }__slide-content`}>
									<p className={`${ ns }__slide-title`}>
										{flatProduct.title}
									</p>
									<p className={`${ ns }__slide-price`}>
										From
										<br />
										{formatPrice(
											flatProduct.currencyCode,
											Number(flatProduct.amount)
										)}
									</p>
								</div>
							</div>
						);
					})}
				</Slider>
			</div>
		</div>
	);
};

export default CartProductSlider;
