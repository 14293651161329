/* eslint-disable no-console */

import React, { useContext, useState, useEffect } from 'react';
import classNames from 'classnames';

import CartFlyout from '../../components/CartFlyout';
import Footer from '../Footer';
import Header from '../Header';
import VerificationModal from '../../components/RegisterAccount/VerificationModal';
import { PageSpinner } from '../../components/Spinner';
import { AuthContext } from '../../context/auth-context';

const ns = `layout-default`;

const DefaultLayout = ({ children, location }) => {
	const { pathname } = location;
	const rootClassnames = classNames({
		[`${ ns }`]: true,
	});

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isVerifying, setIsVerifying] = useState(false);
	const [isVerificationAlertVisible, setIsVerificationAlertVisible] = useState(false);
	const { state: authState, actions: authActions } = useContext(AuthContext);
	const { isInitialized, isInitializing, auth } = authState;
	const isLoading = isInitializing || !isInitialized;

	const handleRequestClose = () => {
		setIsModalOpen(false);
		setIsVerificationAlertVisible(false);
		setIsVerifying(false);
	};

	const handleVerificationAction = async () => {
		try {
			setIsVerifying(true);
			setIsVerificationAlertVisible(false);
			const profile = await authActions.getAuth0Profile(true);

			if (!profile.emailVerified) {
				setIsVerificationAlertVisible(true);
			} else {
				setIsModalOpen(false);
			}
		} catch (error) {
			console.error(error.json || error);
		} finally {
			setIsVerifying(false);
		}
	};

	const handleSignOutAction = () => {
		authActions.signOut();
		handleRequestClose();
	};

	const spinnerMarkup = isLoading && <PageSpinner />;
	const contentMarkup = !isLoading && children;
	const modalMarkup = !pathname.includes('/account/register') &&
		!isLoading &&
		isModalOpen && (
			<VerificationModal
				isOpen={isModalOpen}
				isVerifying={isVerifying}
				isVerificationAlertVisible={isVerificationAlertVisible}
				onVerificationAction={handleVerificationAction}
				onSignOutAction={handleSignOutAction}
				onRequestClose={handleRequestClose}
				modalProps={{
					shouldHideCloseButton: true,
					shouldCloseOnEsc: false,
					shouldCloseOnOverlayClick: false,
				}}
			/>
		);

	useEffect(() => {
		const emailVerified = async () => {
			const isLocalEmailVerified = auth.profile.emailVerified;

			if (!isLocalEmailVerified) {
				const dbProfile = await authActions.getAuth0Profile(true);
				return !!dbProfile.emailVerified;
			}

			return !!isLocalEmailVerified;
		};

		(async () => {
			if (auth.isAuthenticated) {
				try {
					const isEmailVerified = await emailVerified();
					if (!isEmailVerified) {
						setIsModalOpen(true);
					} else {
						handleRequestClose();
					}
				} catch (error) {
					console.error(error.json || error);
					handleRequestClose();
				}
			} else {
				handleRequestClose();
			}
		})();
	}, [auth.isAuthenticated, auth.profile.emailVerified]);

	return (
		<>
			<div className={rootClassnames}>
				<Header pathname={pathname} />
				<main>
					{spinnerMarkup}
					{contentMarkup}
					{modalMarkup}
				</main>
				<Footer pathname={pathname} />
			</div>
			<CartFlyout />
		</>
	);
};

export default DefaultLayout;
